import * as React from "react";
// Global Layout

import Layout from "../components/layout/layout";

// Components
import FullWidthContainer from "../components/full-width-container/full-width-container";
import NestedContainer from "../components/nested-container/nested-container";
import RowEqual from "../components/row/row-equal";
import SectionHeader from "../components/section-header/section-header";

//Stylesheets
import "./financials.css";

const FinancialsPage = () => {
  return (
    <Layout pageTitle="2021 BCBSRI Mission Report - Financial Results">
      <div className="financials-page">
        <FullWidthContainer background={`var(--color-17)`}>
          <NestedContainer>
            <SectionHeader
              reportType={"financials"}
              title={"2021 Results"}
              intro={
                "Blue Cross & Blue Shield of Rhode Island realized an after-tax net gain of almost $13.2 million in 2021. These results reflect a continued trend of lower-than-anticipated medical costs seen during the COVID-19 pandemic, allowing the company to reinvest in serving members and the community, addressing critical needs in areas including COVID-19 relief, food security, housing, and health equity."
              }
            />
            <p className="note">
              NOTE: Numbers may not add up due to rounding.
            </p>
          </NestedContainer>
          <NestedContainer
            className="container--financials"
            background={"#F1F0EC"}
            border={"thin-grey"}
          >
            <RowEqual divider>
              <div className="financials-number-block">
                <h2>
                  <sup>$</sup>13,168,000
                </h2>
                Overall Profit/Loss
              </div>
              <div>
                <h3 className="left">Overall Profit/Loss</h3>
                <p>
                  For the fifth consecutive year, we have shown positive
                  financial results, allowing us to contribute almost $18.9
                  million into reserves, money we set aside for the protection
                  of all our members to pay future claims.
                </p>
              </div>
            </RowEqual>
            <div className="mt-3">
              <RowEqual divider>
                <div className="financials-number-block">
                  <h2>
                    <sup>$</sup>434,693,000
                  </h2>
                  Statutory Reserves as of 12/31/2021
                </div>
                <div>
                  <h3 className="left">Statutory Reserves</h3>
                  <p>
                    In 2021, statutory reserves that we held for the protection
                    of our members totaled $434.7 million at year-end.
                  </p>
                </div>
              </RowEqual>
            </div>
          </NestedContainer>
          <NestedContainer
            className="container--financials"
            background={"#F1F0EC"}
            border={"thin-grey"}
          >
            <RowEqual divider>
              <div className="center">
                <div>
                  <h2 className="heading-dark">
                    <sup>$</sup>1,795,520,000
                  </h2>
                  Premium Revenue
                  <div className="financials-plus-sign">+</div>
                </div>
                <div className="mb-4">
                  <h2 className="heading-dark">
                    <sup>$</sup>17,130,000
                  </h2>
                  Investment Revenue
                </div>
                <div className="financials-number-block">
                  <h2>
                    <sup>$</sup>1,812,650,000
                  </h2>
                  Total Revenue
                </div>
              </div>
              <div>
                <h3 className="left">Total Revenue</h3>
                <p>
                  In 2021, we recorded $1.8 billion in premiums from members
                  (individuals and employers). We also recorded a $17.1 million
                  gain on investment revenue.
                </p>
              </div>
            </RowEqual>
          </NestedContainer>
          <NestedContainer
            className="container--financials"
            background={"#F1F0EC"}
            border={"thin-grey"}
          >
            <RowEqual divider>
              <div className="center">
                <div>
                  <h2 className="heading-dark">
                    <sup>$</sup>1,542,710,000{" "}
                  </h2>
                  Payments to Providers
                  <div className="financials-plus-sign">+</div>
                </div>
                <div>
                  <h2 className="heading-dark">
                    <sup>$</sup>238,884,000
                  </h2>
                  Administrative Expenses
                  <div className="financials-plus-sign">+</div>
                </div>
                <div className="mb-4">
                  <h2 className="heading-dark">
                    {" "}
                    <sup>$</sup>17,889,000
                  </h2>
                  Other Expenses
                </div>
                <div className="financials-number-block">
                  <h2>
                    <sup>$</sup>1,799,482,000
                  </h2>
                  Total Expenses
                </div>
              </div>
              <div>
                <h3 className="left">Total Expenses</h3>
                <p>
                  From total revenue, more than $1.5 billion was used for
                  medical and dental claims for our members. This amount
                  accounted for 85.9% of premium revenue collected. The company
                  spent $238.9 million (including premium taxes) on expenses to
                  support the core operations of our business, or 13.3 % of
                  premiums. An additional $17.9 million was spent on other
                  expenses, including $3.3 million on assessments required by
                  the Affordable Care Act.
                </p>
              </div>
            </RowEqual>
          </NestedContainer>
          <NestedContainer
            className="container--financials mb-6"
            background={"#F1F0EC"}
            border={"thin-grey"}
          >
            <RowEqual divider>
              <div className="financials-number-block">
                <h2>
                  <sup>$</sup>13,168,000
                </h2>
                Net Gain
              </div>
              <div>
                <h3 className="left">Net Gain</h3>
                <p>
                  Overall, total revenue stood at just over $1.8 billion, and
                  expenses were just under $1.8 billion in 2021, resulting in a
                  net gain of almost $13.2 million.
                </p>
              </div>
            </RowEqual>
          </NestedContainer>

          <NestedContainer className="disclaimer">
            <p>
              This formulation represents unaudited results utilizing Statutory
              Accounting Principles. The Blue Cross and Blue Shield Association
              licenses Blue Cross & Blue Shield of Rhode Island to offer certain
              products and services under the Blue Cross and Blue Shield brand
              names. Blue Cross & Blue Shield of Rhode Island is an independent
              organization governed by its own Board of Directors and solely
              responsible for its own debts and other obligations. Neither the
              Association nor any other organization using the Blue Cross and
              Blue Shield brand names acts as a guarantor of Blue Cross & Blue
              Shield of Rhode Island’s obligations. A copy of Blue Cross & Blue
              Shield of Rhode Island’s most recent financial statements is
              available at the state{" "}
              <a
                href="https://dbr.ri.gov/divisions/insurance/financial.php"
                target="_blank"
                rel="noreferrer"
              >
                Department of Business Regulation.
              </a>
            </p>
          </NestedContainer>
        </FullWidthContainer>
      </div>
    </Layout>
  );
};

export default FinancialsPage;
